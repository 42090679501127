// airwallex sdk
export const useNuveiHooks = () => {
  const initNuveiSdk = async (options: {
    env: "init" | "prod"
    session_token: string
    key: string
    callback: (type: "success" | "error") => any
  }) => {
    const MerchantId = "4191741149627662934"
    const MerchantSiteId = "257048"

    if (!document.querySelector("#nuvei_p")) {
      await asyncAppendScript(`https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js`, {
        id: "nuvei_p"
      })
    }

    // Instantiate Nuvei API
    const sfc = window.SafeCharge({
      env: options.env, // Nuvei API environment - 'int' (integration) or 'prod' (production - default if omitted)
      merchantId: MerchantId, //as assigned by Nuvei
      merchantSiteId: MerchantSiteId //as assigned by Nuvei
    })

    sfc.createPayment(
      {
        sessionToken: options.session_token,
        // merchantId: MerchantId,
        // merchantSiteId: MerchantSiteId,
        // clientUniqueId: options.client_unique_id,
        paymentOption: {
          alternativePaymentMethod: {
            paymentMethod: options.key
          }
        }
        // billingAddress: {
        //   firstName: options.first_name,
        //   lastName: options.last_name
        // }
      },
      function (res) {
        if (
          isObject(res) &&
          Object.prototype.hasOwnProperty.call(res, "status") &&
          (res as { [x: string]: any })?.status === "SUCCESS"
        ) {
          options.callback("success")
        } else {
          options.callback("error")
        }
      }
    )
  }

  return {
    initNuveiSdk
  }
}
