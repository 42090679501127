import { CSSProperties } from "vue"

export enum IComboCardType {
  Normal = 1,
  Contact = 2,
  Popular = 3
}
export interface ICardDetailData {
  titleStyle?: CSSProperties
  wrapperStyle?: CSSProperties
  count?: string | number
  normalPrice?: string | number
  price?: string | number
  priceFlag?: boolean
  priceFlagStyle?: boolean
  save?: string
  customDesc?: string | number
  singlePrice?: string | number
  device?: string | number
  btnText: string
  key?: any
  type?: IComboCardType
  descs?: string[]
  pkgName1?: string
  pkgName2?: string
  popular?: boolean
  pkgUnit?: string
  isBackUnit?: boolean
  extraCredits?: string
  extraCredits1?: string
  extraValidate?: string
  duration_seconds?: number
  validText?: string
  u_discount_per_credit_price?: string
  u_discount_price?: string
  u_discount_amount?: string
  u_discount_amount_by_k?: string
  [x: string]: any
}
