// airwallex sdk
export const useAirWallexHooks = () => {
  const initAirWallexSdk = async (options: {
    env: "staging" | "demo" | "prod"
    intent_id: string
    client_secret: string
    required_contact_field: string
    currency: string
    successUrl: string
    failUrl: string
    key: string
  }) => {
    if (!document.querySelector("#airwallex_p")) {
      await asyncAppendScript(`https://checkout.airwallex.com/assets/elements.bundle.min.js`, { id: "airwallex_p" })
    }
    window.Airwallex.init({
      locale: useI18nCountry().value,
      env: options.env, // Setup which Airwallex env('demo' | 'prod') to integrate with
      origin: window.location.origin // Set up your event target to receive the browser events message
    })

    window.Airwallex.redirectToCheckout({
      env: options.env, // Which env('staging' | 'demo' | 'prod') you would like to integrate with
      mode: "payment",
      intent_id: options.intent_id,
      client_secret: options.client_secret,
      currency: options.currency,
      withBilling: true,
      requiredBillingContactFields: options.required_contact_field,
      successUrl: options.successUrl, // 支付成功后跳转页面，必须是HTTPS
      failUrl: options.failUrl, // 支付失败后跳转页面，必须是HTTPS
      logoUrl: getCloudAssets("/images/logo-5.png"), //商户logo展示，必须是HTTPS
      locale: useI18nCountry().value, //前端语言设置，目前支持en, zh, ja, ko, ar, es, de, fr, it, nl
      methods: [options.key] //传入数组，控制展示和排序支付方式，示例只展示卡							//methods里其他值参见支付方式
    })
  }

  return {
    initAirWallexSdk
  }
}
