import { getPaddleComboSubDesc } from "@/constant/pages/comboSub"

export const useComboSubListenDom = () => {
  const comboSubDescList = computed(() => {
    return getPaddleComboSubDesc()
  })

  const executeSubDescListEvent = () => {
    const customerSupportDom = document.querySelector(".paddle-payment-sub-b-2 .paddle-payment-sub-click")
    const termsDom = document.querySelector(".paddle-payment-sub-b-2 .paddle-payment-sub-b-t1")
    const privacyDom = document.querySelector(".paddle-payment-sub-b-2 .paddle-payment-sub-b-t2")
    const policyDom = document.querySelector(".paddle-payment-sub-b-2 .paddle-payment-sub-b-t3")

    const { getPrivacy, getTerms } = useGetPolicy()

    termsDom &&
      termsDom.addEventListener(
        "click",
        useDebounceFn(() => {
          getTerms()
        }, 300)
      )

    privacyDom &&
      privacyDom.addEventListener(
        "click",
        useDebounceFn(() => {
          getPrivacy()
        }, 300)
      )

    policyDom &&
      policyDom.addEventListener(
        "click",
        useDebounceFn(() => {
          useLocationOpen(getCloudAssets("/policy/refund.html"))
        }, 300)
      )

    customerSupportDom &&
      customerSupportDom.addEventListener(
        "click",
        useDebounceFn(() => {
          // 如果判断是有im，打开im
          if (useImGroupId().value) {
            window.LiveChatWidget && window.LiveChatWidget.call("maximize")
          } else {
            // 打开contact us
            useContactUsDialog()
          }
        }, 300)
      )
  }

  return {
    comboSubDescList,
    executeSubDescListEvent
  }
}
