import PaddelDialog from "@/components/pages/payment/PaddelDialog.vue"
import { createVNode, render } from "vue"

interface IPaddleOptions {
  subTotal?: string
  discount_price?: string
  isNewSub?: boolean
  trade_no?: string
  checkout_url?: string
  isPassThrough?: boolean
  isRedeem?: boolean
  isBackUnit?: boolean | string // 有值单位就是放后面
  uuid?: string // 记录落地页购买次数需要
  char?: string
  orderInfo?: any
  showDialog?: boolean
  topTip?: string
  unit?: string
  showBottomTip?: boolean
  isPointer?: boolean
  paddleNoLoginPassThroughUUid?: string
  landingBfConfig?: {
    gift_card_receiver: string
    sender_name: string
  }
  showBeforeDesc?: boolean
  showAfterSubDesc?: boolean
  paymentSuccess?: (...args: any[]) => any
  paymentFail?: (...args: any[]) => any
  onClose?: (...args: any[]) => any
}

let showPaddleDialog = false

export const usePaddleDialog = (options: IPaddleOptions = {}) => {
  if (showPaddleDialog) {
    return
  }
  const showDialog = ref(true)
  const vm = createVNode(PaddelDialog, {
    ...options,
    showDialog,
    onClose() {
      showDialog.value = false
      showPaddleDialog = false
    }
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
  showPaddleDialog = true
}
