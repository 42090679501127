import { ThanksDialogTextType } from "@/composables/api/common"

export interface IThanksDialogObj {
  topT1: string
  topT2: string
  centerT1: string
  centerList: string[]
  centerBtn: string
  centerDesc: string
  bottomT1: string
  bottomBtn: string
}

export const getThanskDialogTextConfig = (type: ThanksDialogTextType): IThanksDialogObj => {
  return {
    [ThanksDialogTextType.Type1]: {
      topT1: useTranslateI18n(`download.thanks.welc`),
      topT2: useTranslateI18n(`download.thanks.d1`),
      centerT1: useTranslateI18n(`download.thanks.d2`),
      centerList: [
        useTranslateI18n(`download.thanks.d3`),
        useTranslateI18n(`download.thanks.d4`),
        useTranslateI18n(`download.thanks.d5`)
      ],
      centerBtn: useTranslateI18n(`download.thanks.d6`),
      centerDesc: useTranslateI18n(`download.thanks.d7`),
      bottomT1: useTranslateI18n(`download.thanks.d10`),
      bottomBtn: useTranslateI18n(`download.thanks.d11`)
    },
    [ThanksDialogTextType.Type2]: {
      topT1: useTranslateI18n(`download.thanks.d8`),
      topT2: useTranslateI18n(`download.thanks.d12`),
      centerT1: useTranslateI18n(`download.thanks.d2`),
      centerList: [
        useTranslateI18n(`download.thanks.d3`),
        useTranslateI18n(`download.thanks.d4`),
        useTranslateI18n(`download.thanks.d5`)
      ],
      centerBtn: useTranslateI18n(`download.thanks.d6`),
      centerDesc: useTranslateI18n(`download.thanks.d7`),
      bottomT1: useTranslateI18n(`download.thanks.d10`),
      bottomBtn: useTranslateI18n(`download.thanks.d11`)
    },
    [ThanksDialogTextType.Type3]: {
      topT1: useTranslateI18n(`download.thanks.d8`),
      topT2: useTranslateI18n(`download.thanks.d9`, [1]),
      centerT1: useTranslateI18n(`download.thanks.d2`),
      centerList: [
        useTranslateI18n(`download.thanks.d3`),
        useTranslateI18n(`download.thanks.d4`),
        useTranslateI18n(`download.thanks.d5`)
      ],
      centerBtn: useTranslateI18n(`download.thanks.d6`),
      centerDesc: useTranslateI18n(`download.thanks.d7`),
      bottomT1: useTranslateI18n(`download.thanks.d10`),
      bottomBtn: useTranslateI18n(`download.thanks.d11`)
    }
  }[type]
}
