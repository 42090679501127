import { CusComboApi } from "@/constant/api/cusCombo"
import http from "@/http/index"
import { ILandingComboProduct } from "./combo"
import { ProductType } from "@/composables/api/common"

export enum ICusComboType {
  Enterprise = 1,
  Normal = 2
}

type ICusComboInfo = Pick<
  ILandingComboProduct,
  "subscription" | "pm_config" | "product" | "current_plan" | "product_type"
> & {
  custom_link: {
    description: string
    evoto_account: string
    payment_due_by: string
    pay_mode: ProductType
    quotation: string
    state: boolean
    email: string
    company_name: string
    register_country: any
    type: ICusComboType
    org_name?: string
    card_tail?: string
    expires?: string
  }
}

export const getCusComboInfo = <T = ICusComboInfo>(uuid: string) => {
  return http.get<T>(CusComboApi.GET_CUS_COMBO, { uuid })
}

export const getCusComboPassThrough = <T = any>(uuid: string) => {
  return http.get<T>(CusComboApi.GET_PASSTHROUGH, { uuid })
}
