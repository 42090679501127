import paymentLocale from "~~/lang/en/payment.json"
import { ICountryDict } from "@/constant/i18n-country"
import { useTranslateI18n } from "~~/composables/store/i18n-country"

// 支付成功超过多少张数显示 联系方式
export const showMoreInfoCredits = 3600

// 支付成功包含国家在内显示 联系方式
export const showMoreInfoCountry = [ICountryDict.US, ICountryDict.JP, ICountryDict.KR]

// 暂时写死
export const validateDate = () => {
  return computed(
    () =>
      `${useTranslateI18n(`payment.comboPayment.carousel.validity`, [
        `1  ${useTranslateI18n("payment.additionCard.year")}`
      ])}`
  )
}

// will you get  list
export const getDescList = () => {
  return paymentLocale.payment.comboPayment.get.list.map((str, index) => {
    return useTranslateI18n(`payment.comboPayment.get.list[${index}]`)
  })
}
// 套餐渐变色值
export const gradientList: { bg: string; textStyle: string }[] = [
  {
    bg: `#F90`,
    textStyle: `#F90`
  },
  {
    bg: `#FD5A5A`,
    textStyle: `#FD5A5A`
  },
  {
    bg: `#8273EC`,
    textStyle: `#8273EC`
  },
  {
    bg: `#5A9BFD`,
    textStyle: `#5A9BFD`
  },
  {
    bg: `#00C2FF`,
    textStyle: `#00C2FF`
  },
  {
    bg: `#FFE050`,
    textStyle: `#FFE050`
  }
]

// 支付渠道选项
export enum PaymentRadioSelect {
  pay_vn = "9pay_vn",
  vietqr_vn = "vietqr_vn", // viqr 扫码 越南
  fpx_my = "fpx_my", //  马来西亚
  boost_my = "boost_my", // 马来西亚
  touchngo_my = "touchngo_my", // 马来西亚
  dragonpay_ph = "dragonpay_ph", // 菲律宾
  gcash_ph = "gcash_ph", // 菲律宾
  thaiqr_th = "thaiqr_th", //泰国
  promptpay_th = `promptpay_th`, //泰国
  shopeepay_th = `shopeepay_th`, // 泰国
  ovo_id = `ovo_id`, // 印尼
  dana_id = `dana_id`, // 印尼
  linkaja_id = `linkaja_id`, //印尼
  shopeepay_id = "shopeepay_id", // 印尼
  creditcard_kr = "creditcard_kr", //韩国
  kakaopay_kr = "kakaopay_kr", // 韩国
  payco_kr = "payco_kr", // 韩国
  samsungpay_kr = "samsungpay_kr", // 韩国
  ssgpay_kr = "ssgpay_kr", // 韩国
  naverpay_kr = "naverpay_kr"
}

// export const PaymentRadioSelectDict = {
//   [PaymentRadioSelect.pay_vn]: {
//     label: PaymentRadioSelect.pay_vn,
//     src: getCloudAssets("/images/pages/payment/9pay_vn.png")
//   },
//   [PaymentRadioSelect.vietqr_vn]: {
//     label: PaymentRadioSelect.vietqr_vn,
//     src: getCloudAssets("/images/pages/payment/vietQr.png")
//   },
//   [PaymentRadioSelect.fpx_my]: {
//     label: PaymentRadioSelect.fpx_my,
//     src: getCloudAssets("/images/pages/payment/fpx_my.png")
//   },
//   [PaymentRadioSelect.boost_my]: {
//     label: PaymentRadioSelect.boost_my,
//     src: getCloudAssets("/images/pages/payment/boost_my.png")
//   },
//   [PaymentRadioSelect.touchngo_my]: {
//     label: PaymentRadioSelect.touchngo_my,
//     src: getCloudAssets("/images/pages/payment/touchngo_my.png")
//   },
//   [PaymentRadioSelect.dragonpay_ph]: {
//     label: PaymentRadioSelect.dragonpay_ph,
//     src: getCloudAssets("/images/pages/payment/dragonpay_ph.png")
//   },
//   [PaymentRadioSelect.gcash_ph]: {
//     label: PaymentRadioSelect.gcash_ph,
//     src: getCloudAssets("/images/pages/payment/globe_ph.png")
//   },
//   [PaymentRadioSelect.thaiqr_th]: {
//     label: PaymentRadioSelect.thaiqr_th,
//     src: getCloudAssets("/images/pages/payment/thaiqr_th.png")
//   },
//   [PaymentRadioSelect.promptpay_th]: {
//     label: PaymentRadioSelect.promptpay_th,
//     src: getCloudAssets("/images/pages/payment/promptpay_th.png")
//   },
//   [PaymentRadioSelect.shopeepay_th]: {
//     label: PaymentRadioSelect.shopeepay_th,
//     src: getCloudAssets("/images/pages/payment/shopeepay_th.png")
//   },
//   [PaymentRadioSelect.ovo_id]: {
//     label: PaymentRadioSelect.ovo_id,
//     src: getCloudAssets("/images/pages/payment/ovo_id.png")
//   },
//   [PaymentRadioSelect.dana_id]: {
//     label: PaymentRadioSelect.dana_id,
//     src: getCloudAssets("/images/pages/payment/dana_id.png")
//   },
//   [PaymentRadioSelect.linkaja_id]: {
//     label: PaymentRadioSelect.linkaja_id,
//     src: getCloudAssets("/images/pages/payment/linkaja_id.png")
//   },
//   [PaymentRadioSelect.shopeepay_id]: {
//     label: PaymentRadioSelect.shopeepay_id,
//     src: getCloudAssets("/images/pages/payment/shopeepay_th.png")
//   },
//   [PaymentRadioSelect.creditcard_kr]: {
//     label: PaymentRadioSelect.creditcard_kr,
//     src: getCloudAssets("/images/pages/payment/creditcard_kr.webp")
//   },
//   [PaymentRadioSelect.kakaopay_kr]: {
//     label: PaymentRadioSelect.kakaopay_kr,
//     src: getCloudAssets("/images/pages/payment/kakaopay_kr.webp")
//   },
//   [PaymentRadioSelect.payco_kr]: {
//     label: PaymentRadioSelect.payco_kr,
//     src: getCloudAssets("/images/pages/payment/payco_kr.webp")
//   },
//   [PaymentRadioSelect.samsungpay_kr]: {
//     label: PaymentRadioSelect.samsungpay_kr,
//     src: getCloudAssets("/images/pages/payment/samsungpay_kr.webp")
//   },
//   [PaymentRadioSelect.ssgpay_kr]: {
//     label: PaymentRadioSelect.ssgpay_kr,
//     src: getCloudAssets("/images/pages/payment/ssgpay_kr.webp")
//   }
// }

export enum Payment_Method {
  locale = 1,
  paddle = 2,
  savedCard = 3
}

export const paymentMethodDict = () => {
  return [
    {
      label: useTranslateI18n(`payment.comboPayment.select1`),
      value: Payment_Method.locale,
      icon: getCloudAssets("/images/pages/payment/e-wallet.png")
    },
    {
      label: useTranslateI18n(`payment.comboPayment.select2`),
      value: Payment_Method.paddle,
      icon: getCloudAssets("/images/pages/payment/credit-card.png")
    }
  ]
}

export const paymentSubMethodDict = () => {
  return [
    {
      title: useTranslateI18n(`payment.comboSub.t29`),
      label: "**** **** **** 1111(12/19)",
      value: Payment_Method.savedCard,
      icon: getCloudAssets("/images/pages/paymentComboSub/visa.png")
    },
    {
      label: useTranslateI18n(`payment.comboPayment.select1`),
      value: Payment_Method.locale,
      icon: getCloudAssets("/images/pages/paymentComboSub/e-wallet.png"),
      bg: getCloudAssets("/images/pages/paymentComboSub/f-1.png")
    },
    {
      label: useTranslateI18n(`payment.comboPayment.select2`),
      value: Payment_Method.paddle,
      icon: getCloudAssets("/images/pages/paymentComboSub/paypal.png"),
      bg: getCloudAssets("/images/pages/paymentComboSub/f-2.png")
    }
  ]
}
